import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  textArtworkCount: {
    color: Colors.Grey8,
  },
  pagination: {
    '&.MuiPagination-root': {
      marginTop: 40,
      display: 'flex',
      justifyContent: 'center',
    },
  },
}));

export default useStyles;
