import { Grid } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, createSearchParams, useSearchParams } from 'react-router-dom';

import PaginationCustom from 'commons/PaginationCustom';
import Text from 'commons/Text';
import API from 'configs/API';
import usePagination from 'hooks/usePagination';
import Routes from 'utils/Route';

import HeaderListArtist from '../HeaderListArtist';
import useStyles from './styles';

const GalleryArtist = ({ tokenParam }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { artistId } = useParams();
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('token');
  const [searchParams] = useSearchParams();

  const objectParamsFromSearchParams = Object.fromEntries(searchParams);
  const params = {
    ...objectParamsFromSearchParams,
    user_uuid: artistId,
    token: token,
  };

  const { data: dataListArtist, isPending: isPendingListArtist } = useQuery({
    queryKey: [API.ARTWORK.LIST_ARTIST_NAME_FOR_COLLECTOR, params],
  });

  const handleRouter = (artist) => {
    const url = Routes.UserArtistArtwork(artistId, artist?.uuid || artist?.id);

    if (tokenParam) {
      const searchParams = createSearchParams({ token: tokenParam });
      navigate({
        pathname: url,
        search: searchParams.toString(),
      });
    } else {
      navigate(url);
    }
  };

  const { page, totalPage, onPageChange } = usePagination(
    dataListArtist?.count,
    dataListArtist?.page_size,
  );

  const handlePageChange = (_, page) => {
    onPageChange(page);
  };

  const listArtist = dataListArtist?.results || [];

  if (isPendingListArtist) {
    return;
  }

  return (
    <div>
      <HeaderListArtist placeholder={t('search_artist_name')} />
      <Grid
        container
        spacing={2}
        sx={{ mt: 2 }}
      >
        {listArtist.map((artist, index) => (
          <Grid
            item
            xs={12}
            md={4}
            key={index}
            sx={{ mb: 2 }}
          >
            <div onClick={() => handleRouter(artist)}>
              <Text fontWeightMedium>
                {artist?.artist_name}{' '}
                {artist?.year_of_birth ? `(B.${artist.year_of_birth})` : '(N/A)'}
              </Text>
              <Text
                type="sm"
                className={classes.textArtworkCount}
              >
                {artist?.artwork_count} {t('artworks')}
              </Text>
            </div>
          </Grid>
        ))}
      </Grid>

      {!!totalPage && (
        <PaginationCustom
          paginationStyle={classes.pagination}
          count={totalPage}
          page={page}
          onChange={handlePageChange}
        />
      )}
    </div>
  );
};

export default GalleryArtist;
