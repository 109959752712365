import { Stack } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import IosSwitch from 'commons/IosSwitch';
import SearchInputIcon from 'commons/Search/SearchInputIcon';
import Text from 'commons/Text';
import { useSearch } from 'hooks/useSearch';

import useStyles from './styles';

const TabArtworkHeader = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { search, handleSearch } = useSearch();
  const [searchParams, setSearchParams] = useSearchParams();

  const isPublicParam = searchParams.get('is_public');
  const isPublic = isPublicParam === 'false' ? false : true;

  const hanleToggleButton = () => {
    searchParams.delete('page');

    if (isPublic) {
      searchParams.set('is_public', false);
      setSearchParams(searchParams);
    } else {
      searchParams.delete('is_public');
      setSearchParams(searchParams);
    }
  };

  return (
    <div className={classes.wrapper}>
      <SearchInputIcon
        value={search}
        onChange={handleSearch}
        placeholder={t('search_by_id_or_artwork_name')}
        wrapStyle={classes.searchWidth}
      />

      <Stack
        direction={'row'}
        sx={{ alignItems: 'center' }}
        spacing={1}
      >
        <Text fontWeightMedium>Private</Text>
        <IosSwitch
          isChecked={!isPublic}
          onSwitchChange={hanleToggleButton}
        />
      </Stack>
    </div>
  );
};

export default TabArtworkHeader;
